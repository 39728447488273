{
	"type": "FeatureCollection",
	"name": "Empty",
	"features": [
		{
			"type": "Feature",
			"geometry": {
				"type": "Polygon",
				"coordinates": [
					[
						[
							8.8649450363,
							49.9635679569
						]
					]
				]
			},
			"properties": {}
		}
	]
}