import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { OverviewMapComponent } from './overviewmap/overviewmap.component';
import { MapComponent } from './map/map.component';

//-----------------------------------------------------------------------------------------------------------
@Injectable({
  providedIn: 'root'
})
export class RoutingGuard implements CanActivate {

  constructor() {
    // this.auth.checkLogin(true);    
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return true;
  }
}


//-----------------------------------------------------------------------------------------------------------
const APP_ROUTES: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'karte', component: OverviewMapComponent },
  { path: '', pathMatch: 'full', redirectTo: 'karte' },
  { path: '**', component: OverviewMapComponent },
];



//-----------------------------------------------------------------------------------------------------------
@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],  //  { useHash: true }
  exports: [RouterModule],
  //providers: [{provide: RouteReuseStrategy, useClass: CustomReuseStrategy}],
})
export class AppRoutingModule {
}
