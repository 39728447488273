import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Output, Directive } from '@angular/core';

import { environment } from '../environments/environment';
import { Observable } from 'rxjs';


//-----------------------------------------------------------------------------------------------------------
@Directive()
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) {
  }

  public delete(url: string): Observable<any> {

    return this.http.delete(environment.config.servicesBaseUrl + url);
  }

  public post(url: string, data): Observable<any> {

    return this.http.post(environment.config.servicesBaseUrl + url, data)
  }

  public get(url: string, param?): Observable<any> {

    return this.http.get(environment.config.servicesBaseUrl + url, param);
  }

  public fileDownload(url) {

    const options = {
      // Ignore this part or  if you want full response you have 
      // to explicitly give as 'boby'as http client by default give res.json()
      observe: 'response' as 'body',

      // have to explicitly give as 'blob' or 'json'
      responseType: 'blob' as 'blob'
    };

    return this.http.get(environment.config.servicesBaseUrl + url, options)
  }

  public csvDownload(url, input) {

    const options = {
      // Ignore this part or  if you want full response you have 
      // to explicitly give as 'boby'as http client by default give res.json()
      observe: 'response' as 'body',

      // have to explicitly give as 'blob' or 'json'
      responseType: 'blob' as 'blob',

      params: input.params
    };

    return this.http.get(environment.config.servicesBaseUrl + url, options)
  }


}
