<div>
    <select class="form-control selectStakeholder" [formControl]="selectData" style="margin-left: 5px;"
        *ngIf="!stakeholderId">
        <option value="">Wähle einen Bereich</option>
        <option *ngFor="let s of mapOptions" value="{{s.id}}">{{s.name}}</option>
    </select>

    <div class="modes" *ngIf="modes && modes.length > 1">
        <div class="btn mode-btn {{getBtnActiveClass('breakup')}}" *ngIf="modes.includes('breakup')" (click)="changeMode('breakup')">Baustellen</div>
        <div class="btn mode-btn {{getBtnActiveClass('allFailures')}}" *ngIf="modes.includes('allFailures')" (click)="changeMode('allFailures')">
            Ausfälle
        </div>
        <div class="btn mode-btn {{getBtnActiveClass('strom')}}" *ngIf="modes.includes('strom')" (click)="changeMode('strom')">Strom</div>
        <div class="btn mode-btn {{getBtnActiveClass('gas')}}" *ngIf="modes.includes('gas')" (click)="changeMode('gas')">Gas</div>
        <div class="btn mode-btn {{getBtnActiveClass('wasser')}}" *ngIf="modes.includes('wasser')" (click)="changeMode('wasser')">Wasser</div>
        <div class="btn mode-btn {{getBtnActiveClass('fernwärme')}}" *ngIf="modes.includes('fernwärme')" (click)="changeMode('fernwärme')">Fernwärme</div>
        <div class="btn mode-btn {{getBtnActiveClass('tele')}}" *ngIf="modes.includes('tele')" (click)="changeMode('tele')">Medianet</div>
    </div>
</div>

<app-map *ngIf="route && showMap" style="display: block; height: 600px; width: calc(100% - 10px);" [border]="border"
    [route]="route" (onRouteChange)="routeChange($event)"></app-map>

<div class="infoText" *ngIf="showTable && events && events.length == 0">
    Aktuell sind keine Versorgungsunterbrechungen bekannt.
</div>


<table class="table table-striped list-table" *ngIf="showTable && events && events.length > 0">
    <thead>
        <tr class="tableHeadRow">
            
            <th>
                Ort/Straße
            </th>
            <th>
                <ng-container *ngIf="activeMode == 'breakup'">Baumaßnahme</ng-container>
                <ng-container *ngIf="activeMode != 'breakup'">Störung</ng-container>
            </th>
            <th>
                Voraussichtlich bis
            </th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let event of events">
            <tr class="rowHeight tableRow ">
                <td class="{{ getTableRowClass(event) }}">
                    {{ event.address }}
                </td>
                <td class="refField {{ getTableRowClass(event) }}">
                    {{ event.description}}
                </td>
                <td class="dateField {{ getTableRowClass(event) }}" *ngIf="activeMode == 'breakup'">
                    {{event.start | date:'shortDate'}} - {{event.end | date:'shortDate'}}
                </td>
                <td class="dateField {{ getTableRowClass(event) }}" *ngIf="activeMode != 'breakup'">
                    {{event.start | date:'short'}} - {{getEndDate(event) | date: 'short'}}{{ getEndDate(event)==='' ? 'noch unbekannt':''}}
                </td>
                <td class="{{ getTableRowClass(event) }}">
                    <div class="btn btn-primary detailBtn" (click)="showOnMap(event)" *ngIf="showMap && event.mapData != ''">Details zeigen
                    </div>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>