

export const environment = {
  production: true,
  environment: "prod",
  config: {
    servicesBaseUrl: "/api/v1",
  }
};

