import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy, HostListener, NgZone } from '@angular/core';
import { icon, tileLayer, Map, marker, FeatureGroup, Layer, LeafletMouseEvent } from 'leaflet';
import * as L from 'leaflet';

import { Feature, GeoJsonObject } from 'geojson';
import { Router } from '@angular/router';
import { MapService } from '../map.service';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnDestroy {

  @Input()
  public border: GeoJsonObject; //Feature; 

  @Input()
  public route: GeoJsonObject;

  @Input()
  private hardMapSize = false;

  private editRoute: FeatureGroup;  //Feature[];  //L.Layer[];

  private boundsBorder: FeatureGroup;

  // Route
  newRoute: L.Polyline;
  selectedRoute: Layer;

  @Output() onRouteChange = new EventEmitter<any>();

  public tooltipData = null;

  // The map
  private _map: Map;

  set map(map: Map) {
    this._map = map;
  }
  get map(): Map { return this._map; }

  //https://aufbruchverwaltung.e-netz-app.itrm.de/map/tile/{z}/{x}/{y}.png
  //https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
  streetMaps = tileLayer('https://enetzinfomap.e-netz-suedhessen.de/map/tile/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors e-netz Südhessen AG'
  });

  circleLayer = L.layerGroup();

  mapOptions = {
    layers: [this.streetMaps, this.circleLayer],
    zoom: 15,
    printable: true,
    downloadable: true,
    zoomControl: false,
    //preferCanvas: true,
    layersControl: false
    //center: latLng([ 49.87167, 8.65027 ]) // Darmstadt
  };

  iRoute = icon({ iconSize: [50, 50], iconAnchor: [25, 50], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/E_Netz_ICON_Baustelle_aktuell.png', shadowUrl: 'leaflet/marker-shadow.png', shadowRetinaUrl: 'leaflet/marker-shadow.png' })
  iRouteWebPublish = icon({ iconSize: [50, 50], iconAnchor: [25, 50], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/E_Netz_ICON_Baustelle_geplant.png', shadowUrl: 'leaflet/marker-shadow.png', shadowRetinaUrl: 'leaflet/marker-shadow.png' })
  iSelected = icon({ iconSize: [25, 41], iconAnchor: [13, 41], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/marker-icon-red.png', shadowUrl: 'leaflet/marker-shadow.png', shadowRetinaUrl: 'leaflet/marker-shadow.png' })
  // iEdit = icon({ iconSize: [25, 41], iconAnchor: [13, 41], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/marker-icon-orange.png', shadowUrl: 'leaflet/marker-shadow.png', shadowRetinaUrl: 'leaflet/marker-shadow.png' })
  // iEditEnd = icon({ iconSize: [25, 41], iconAnchor: [13, 41], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/marker-icon-orange-arrow.png', shadowUrl: 'leaflet/marker-shadow.png', shadowRetinaUrl: 'leaflet/marker-shadow.png' })
  // iTarget = icon({ iconSize: [44, 44], iconAnchor: [22, 22], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/target-icon.png' })

  iStrom = icon({ iconSize: [50, 50], iconAnchor: [25, 50], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/E_Netz_ICON_Stoerung.png', shadowUrl: 'leaflet/marker-shadow.png', shadowRetinaUrl: 'leaflet/marker-shadow.png' })
  iWater = icon({ iconSize: [50, 50], iconAnchor: [25, 50], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/E_Netz_ICON_Stoerung.png', shadowUrl: 'leaflet/marker-shadow.png', shadowRetinaUrl: 'leaflet/marker-shadow.png' })
  iGas = icon({ iconSize: [50, 50], iconAnchor: [25, 50], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/E_Netz_ICON_Stoerung.png', shadowUrl: 'leaflet/marker-shadow.png', shadowRetinaUrl: 'leaflet/marker-shadow.png' })
  iTele = icon({ iconSize: [50, 50], iconAnchor: [25, 50], shadowSize: [50, 50], shadowAnchor: [15, 50], iconUrl: '/assets/icons/E_Netz_ICON_Stoerung.png', shadowUrl: 'leaflet/marker-shadow.png', shadowRetinaUrl: 'leaflet/marker-shadow.png'})

  colRoute: string = '#455e96';               //#0892ac   #ff8a00     #791533
  colSelected: string = '#a64466';
  colWebPublish: string = '#455e96';
  colEdit: string = '#ff8a00';
  colBorder: string = '#791533';

  // Marker 
  // markerStart = marker([49.875417, 8.625126], { icon: this.iEdit });
  // markerEnd = marker([49.872063, 8.632916], { icon: this.iEditEnd });
  // markerTarget = marker([49.872063, 8.632916], { icon: this.iTarget });

  labelText: string = null;
  pendingRequest: Subscription = null;

  tooltipVisible = false;


  //---------------------------------------------
  constructor(private changeDetector: ChangeDetectorRef, private zone: NgZone, private router: Router, private bs: MapService) {
  }

  ngOnDestroy() {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    if (this.map) {
      this.map.invalidateSize();
    }
  }

  filter(node) {
    return node.className !== 'leaflet-control-container'
      && node.tagName !== 'MAT-TOOLBAR';
  }

  onMapReady(map: Map) {

    this._map = map;
    this.addBorderLayer();
    this.addRouteLayer();

    // ---------------------------------------------
    // Controls

    var zoomCtrl = L.control.zoom({ position: 'topleft', zoomInTitle: 'vergrößern', zoomOutTitle: 'verkleinern' });
    this.map.addControl(zoomCtrl);
    this.map.addControl(L.control.scale({ position: 'bottomleft', imperial: false }));

    // setTimeout(() => {
    //   // L.DomUtil.setOpacity(this.zoomCtrl.getContainer(), 0.7);
    //   this.map.invalidateSize();
    //   // this.map.
    // }, 0)

    map.on("click", e => this.onMapClick(e));
  }

  setBorderLayer(border) {
    this.border = border;
    this.addBorderLayer();
  }

  showOnMap(event) {

    try {

      for (const layer of this.editRoute.getLayers()) {

        const rawLayer: any = layer;

        if (rawLayer.feature.properties.id == event.id) {

          let features: any = {
            "type": "FeatureCollection",
            "name": "Test",
            "features": []
          };
          features.features.push(rawLayer.feature);
          var bounds: FeatureGroup;
          bounds = L.geoJSON(features);
          this.map.fitBounds(bounds.getBounds(), { maxZoom: 18, animate: false });
          
          this.showTooltip({sourceTarget: rawLayer})
          break;
        }

      }
    }
    catch (e) {
      console.error(e);
    }
  }

  addBorderLayer() {

    if (this.boundsBorder) {
      this.boundsBorder.removeFrom(this.map);
    }

    try {

      // Layer: Bounds
      var bounds: FeatureGroup;
      bounds = L.geoJSON(this.border); //.addTo(map);    ---->>>>   GeoJson.features[0] as Feature
      bounds.setStyle({ color: this.colBorder, weight: 2, opacity: 0.8, fillOpacity: 0.05 });
      this.boundsBorder = bounds;
      this.map.addLayer(this.boundsBorder);

      // center Layer
      this.map.fitBounds(this.boundsBorder.getBounds(), { maxZoom: 15, animate: false });

    }
    catch (e) {
      console.error(e);
    }
  }

  addRouteLayer() {

    if (this.editRoute) {
      this.editRoute.removeFrom(this.map);
    }

    this.circleLayer.clearLayers();

    // Layer: Route
    this.editRoute = L.geoJSON(this.route, { onEachFeature: this.onEachFeature.bind(this) });

    this.editRoute.setStyle({ weight: 4, opacity: 1, fillOpacity: 0 });
    this.editRoute.addTo(this.map);

    if (this.route) {
      const bounds = this.route['bounds'];
      if (bounds) {

        this.map.fitBounds([
          [bounds['_northEast'].lat, bounds['_northEast'].lng],
          [bounds['_southWest'].lat, bounds['_southWest'].lng]
        ], { maxZoom: 15, animate: false });
      }
    }

    this.changeDetector.markForCheck();
  }

  onEachFeature(feature: Feature, layer: Layer) {

    if (feature.geometry.type === 'Point') {

      if (feature.properties.webPublish) {
        (layer as L.Marker).setIcon(this.iRouteWebPublish);
      } else {
        if (feature.properties.branch == "S") {
          (layer as L.Marker).setIcon(this.iStrom);
        } else if (feature.properties.branch == "W") {
          (layer as L.Marker).setIcon(this.iWater);
        } else if (feature.properties.branch == "G") {
          (layer as L.Marker).setIcon(this.iGas);
        } else if (feature.properties.branch == "T") {
          (layer as L.Marker).setIcon(this.iTele);
        } else {
          (layer as L.Marker).setIcon(this.iRoute);
        }
      }

      if (feature.properties.radius > 0) {

        L.circle([feature.geometry.coordinates[1], feature.geometry.coordinates[0]], {
          color: '#455e96',
          fillColor: '#455e96',
          fillOpacity: 0.3,
          radius: feature.properties.radius,
        }).addTo(this.circleLayer);
      }

    }

    if (feature.geometry.type === 'LineString') {

      if (feature.properties.webPublish) {
        (layer as L.Polyline).setStyle({ color: this.colWebPublish });
      } else {
        (layer as L.Polyline).setStyle({ color: this.colRoute });
      }

      const pos: any = (layer as L.Polyline).getLatLngs()[0];
      const marker: any = L.marker(pos).setIcon(this.iRoute);

      marker.on("click", e => this.showTooltip(e));
      marker.feature = {};
      marker.feature.properties = feature.properties;
      marker.addTo(this.circleLayer);
    }

    layer.on("click", e => this.showTooltip(e));
    
  }

  async onMapClick(e) {
    let evt: LeafletMouseEvent = e;

    this.changeDetector.detectChanges();
  }

  select(l: Layer) {
    if (this.selectedRoute != null) {
      if (this.selectedRoute instanceof (L.Marker)) {
        (this.selectedRoute as L.Marker).setIcon(this.iRoute);
      }
      if (this.selectedRoute instanceof (L.Polyline)) {
        (this.selectedRoute as L.Polyline).setStyle({ color: this.colRoute });
      }
    }

    if (l != null) {
      if (l instanceof (L.Marker)) {
        (l as L.Marker).setIcon(this.iSelected);
        this.labelText = 'Punkt';
      }

      if (l instanceof (L.Polyline)) {
        (l as L.Polyline).setStyle({ color: this.colSelected });
        this.labelText = 'Strecke';
      }
    }

    this.selectedRoute = l;
    this.changeDetector.detectChanges();
  }

  showTooltip(e) {
    if (e.originalEvent) {
      e.originalEvent.stopPropagation();
    }
    this.tooltipData = e.sourceTarget.feature.properties;
    this.tooltipVisible = true;
    this.changeDetector.detectChanges();
  }

  hideTooltip() {
    this.tooltipVisible = false;
    this.changeDetector.detectChanges();
  }

  onMarkerClick(e) {
    if (this.newRoute == null) {
      this.select(e.sourceTarget);
    }
  }

  onLineClick(e) {
    if (this.newRoute == null) {
      var clickedLine: L.Polyline = e.sourceTarget;
      clickedLine.setStyle({ color: '#ff7900' });
      this.select(clickedLine);
    }
    L.DomEvent.stopPropagation(e);
  }

  public mapSize() {
    if (this.hardMapSize) {
      return 'hardSize';
    }
    return '';
  }

  public navigate(id) {
    this.zone.run(() => {
      this.router.navigate(['/listBreakups', { outlets: { modal: ['breakup', id] } }]);

      // [routerLink]="[ ]"
    });
  }
}