<div class="map-container">

    <div class="map-frame {{mapSize()}}">
        <div id="map" leaflet (leafletMapReady)="onMapReady($event)" [leafletOptions]="mapOptions">
        </div>
    </div>
</div>

<div class="tooltip" *ngIf="tooltipVisible && tooltipData" (click)="hideTooltip()">
    <h2 *ngIf="tooltipData.webPublish" class="tooltip-title">Webveröffentlichung</h2>
    <h2 class="tooltip-title">Adresse: {{tooltipData.address}}</h2>
    <span class="tooltip-label">Beschreibung: </span>{{tooltipData.description}}<br><br>
    <span class="tooltip-label">Vorraussichtlich bis: </span>{{tooltipData.end | date}}<br><br>
    <span class="tooltip-label" *ngIf="tooltipData.website && tooltipData.website != ''">Webseite:
        <a href="{{tooltipData.website}}" target="_blank"> Öffnen</a>
    </span><br><br>
</div>