import { Component, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';



//-----------------------------------------------------------------------------------------------------------
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit, OnDestroy {


  private subs: Subscription[] = [];

  constructor(private cdRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
