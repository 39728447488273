import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Feature } from 'geojson';
import { MapService } from '../map.service';
import { Subscription } from 'rxjs';
import BorderGeoJson from './Poly_Empty.json';
import { FormControl } from '@angular/forms';
import { MapComponent } from '../map/map.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-overviewmap',
  templateUrl: './overviewmap.component.html',
  styleUrls: ['./overviewmap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewMapComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  @ViewChild(MapComponent) map: MapComponent;

  public mapOptions = [];

  public selectData = new FormControl('');

  public border: Feature = BorderGeoJson.features[0] as Feature;  //geoJSON

  public route = null;

  public events = [];

  public activeMode = null;

  private alwaysOwnBorder = false;

  public modes = [];

  public stakeholderId = null;

  public showTable = true;

  public showMap = true;

  constructor(public mapService: MapService, private cdRef: ChangeDetectorRef, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.stakeholderId = this.activatedRoute.snapshot.queryParamMap.get('owner');
    this.selectData.setValue(this.stakeholderId);
    this.modes = this.activatedRoute.snapshot.queryParamMap.getAll('mode');
    this.alwaysOwnBorder = this.activatedRoute.snapshot.queryParamMap.get('alwaysOwnBorder') == 'true';

    if (this.activatedRoute.snapshot.queryParamMap.get('showMap') == 'false') {
      this.showMap = false;
    }

    if (this.activatedRoute.snapshot.queryParamMap.get('showTable') == 'false') {
      this.showTable = false;
    }

    if (!this.modes || this.modes.length == 0) {
      this.modes = ['breakup', 'allFailures', 'gas', 'wasser', 'strom', 'tele', 'fernwärme'];
    }

    this.activeMode = this.modes[0];

    if (!this.stakeholderId) {
      this.subs.push(this.mapService.getMapOptions().subscribe(data => {

        this.mapOptions = data;
        this.selectData.setValue(data[0].id);
        this.cdRef.detectChanges();
      }));
      this.subs.push(this.selectData.valueChanges.subscribe(value => {
        this.loadData();
      }));
    } else {
      this.loadData();
    }
  }

  changeMode(mode) {
    this.activeMode = mode;
    this.loadData();
  }

  showOnMap(event) {

    if (!this.map) {
      return;
    }

    this.map.showOnMap(event);
    scroll(0, 0);
  }

  getBtnActiveClass(name) {

    if (name === this.activeMode) {
      return 'btn-active';
    }
    return '';
  }

  loadData() {

    if (!this.selectData.value || this.selectData.value == null) {
      return;
    }

    if (this.activeMode == 'breakup') {
      this.loadBreakups();
      this.loadStakeholderBorder();
    } else {

      if (this.alwaysOwnBorder || this.activeMode == 'allFailures') {
        this.loadStakeholderBorder();
      } else {
        this.loadBranchBorder(this.activeMode);
      }

      this.loadFailures(this.activeMode);

    }
  }


  loadFailures(branch) {

    this.subs.push(this.mapService.getFailures(branch, this.selectData.value).subscribe(routes => {

      let features = {
        "type": "FeatureCollection",
        "name": "Test",
        "features": []
      };

      for (const entry of routes) {

        if (entry.mapData == null || entry.mapData == '') {
          continue;
        }

        const entryParsed = JSON.parse(entry.mapData);

        for (const feature of entryParsed.features) {
          feature.properties['address'] = entry.address;
          feature.properties['description'] = entry.description;
          feature.properties['webPublish'] = entry.webPublish;
          feature.properties['start'] = entry.start;
          feature.properties['end'] = entry.end;
          feature.properties['radius'] = entry.radius;
          feature.properties['branch'] = entry.branch;
          feature.properties['branchColorCode'] = entry.branchColorCode;
          feature.properties['id'] = entry.id;

          features.features.push(feature);
        }
      }

      this.events = routes;

      this.route = features;
      this.cdRef.detectChanges();
      if (this.map) {
        this.map.addRouteLayer();
      }
      this.cdRef.detectChanges();
    }));
  }

  

  loadBreakups() {

    this.subs.push(this.mapService.getMapData(this.selectData.value).subscribe(routes => {

      let features = {
        "type": "FeatureCollection",
        "name": "Test",
        "features": []
      };

      for (const entry of routes) {

        if (entry.mapData == null || entry.mapData == '') {
          continue;
        }

        const entryParsed = JSON.parse(entry.mapData);

        for (const feature of entryParsed.features) {
          feature.properties['title'] = entry.title;
          feature.properties['address'] = entry.address;
          feature.properties['description'] = entry.description;
          feature.properties['permission'] = entry.permission;
          feature.properties['webPublish'] = entry.webPublish;
          feature.properties['start'] = entry.start;
          feature.properties['end'] = entry.end;
          feature.properties['roadblock'] = entry.roadblock;
          feature.properties['id'] = entry.id;
          feature.properties['website'] = entry.website;

          features.features.push(feature);
        }
      }
      this.events = routes;

      this.route = features;
      this.cdRef.detectChanges();
      if (this.map) {
        this.map.addRouteLayer();
      }
      this.cdRef.detectChanges();
    }));
  }

  loadBranchBorder(branch) {

    this.mapService.getLoadBranchBorder(branch).subscribe(data => {
      this.border = data;

      if (this.map) {
        this.map.setBorderLayer(this.border);
        this.cdRef.detectChanges(); //set new routes in map
        this.map.addRouteLayer();
      }
      this.cdRef.detectChanges();
    }, error => {
      console.error('load border failed' + error.error);
    });
  }

  loadStakeholderBorder() {
    this.mapService.getLoadBorder(this.selectData.value).subscribe(data => {
      this.border = data;

      if (this.map) {
        this.map.setBorderLayer(this.border);
        this.cdRef.detectChanges(); //set new routes in map
        this.map.addRouteLayer();
      }
      this.cdRef.detectChanges();
    }, error => {
      console.error('load border failed' + error.error);
    });
  }

  getEndDate(entry) {
    
    if (entry.end == 'null' || entry.end == null) {
      return ''
    }

    return entry.end;
  }

  getTableRowClass(entry) {

    if (entry.failureClassification == 'Störung') {
      return 'tbl-failure';
    }

    return '';
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
