import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule, RoutingGuard } from './app-routing.module';
import { OverviewMapComponent } from './overviewmap/overviewmap.component';
import { MapComponent } from './map/map.component';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpService } from './http.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { MapService } from './map.service';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';




registerLocaleData(localeDe);


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,

    OverviewMapComponent,
    MapComponent

  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LeafletModule,
    LayoutModule
  ],
  providers: [HttpService, MapService, RoutingGuard,
    { provide: LOCALE_ID, useValue: 'de' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
