import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { buildDate, commitHash } from "src/environments/version";
import { HttpService } from './http.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  version = {
    frontendVersion: commitHash,
    frontendTime: buildDate,
    backendVersion: 'dev',
    backendTime: '1970-01-01'
  };

  title = "eNetzInfoMap";

  private subs: Subscription[] = [];

  constructor(public http: HttpService, private cdr: ChangeDetectorRef) {
  }
  ngOnInit(): void {

    this.subs.push(this.http.get("/version").subscribe((data) => {
      this.version.backendTime = data.buildDate;
      this.version.backendVersion = data.gitCommit;
      this.cdr.detectChanges();

      console.log(JSON.stringify(this.version));
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
