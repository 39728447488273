import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable()
export class MapService {

  constructor(private http: HttpService) { }


  getLoadBorder(stakeholder) {

    if (this.isNumber(stakeholder)) {
      return this.http.get("/breakupMapData/" + stakeholder + "/border");
    } else {
      return this.http.get("/breakupMapData/border?name=" + encodeURIComponent(stakeholder));
    }
  }

  getFailures(branch, stakeholder) {

    return this.http.get("/gridFailures/", {
      params: {
        type: branch,
        stakeholder: stakeholder
      }
    });
  }

  getLoadBranchBorder(branch) {

    return this.http.get("/gridFailures/border/", {
      params: {
        type: branch
      }
    });
  }

  getMapOptions() {
    return this.http.get('/breakupMapOptions');
  }

  getMapData(id) {
    if (this.isNumber(id)) {
      return this.http.get('/breakupMapData/' + id);
    } else {
      return this.http.get('/breakupMapData?name=' + encodeURIComponent(id));
    }
  }

  getGeoAddress(search: string) {

    return this.http.get("/mapAddressSearch/", {
      params: {
        search: search
      }
    });
  }


  isNumber(val) {
    return !isNaN(val);
  }
}
